@media only screen and (min-width: 600px) {
  .testimonialItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px 28px;
    gap: 16px;
    background: #00673e;
    border-radius: 16px;
    max-width: 620px;
    margin-right: 8px;
    height: 288px;
    overflow-y: hidden;
    @media only screen and (max-width: 600px) {
      padding: 40px 16px;
      width: 90vw;
    }
  }
  .reviewContainer {
    color: #ffffff;
    position: relative;
    overflow-y: scroll;
    min-height: 120px;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    box-shadow: inset 0px 0px 10px rgba(0, 103, 62, 0.061);
    border-radius: 8px;
    -webkit-transition: 0.15s ease-in 0s;
    -moz-transition: 0.15s ease-in 0s;
    -o-transition: 0.15s ease-in 0s;
    transition: 0.15s ease-in 0s;
  }

  .review {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1000;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    z-index: 50;
    height: max-content;
  }
  .reviewContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    overflow-y: hidden;
  }

  .reviewContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #008852;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .reviewContainer::-webkit-scrollbar-track {
    -webkit-appearance: none;
    background-color: #00673e00;
  }
}
@media only screen and (max-width: 600px) {
  .testimonialItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: #00673e;
    border-radius: 16px;
    margin-right: 8px;
    overflow-y: hidden;
    padding: 40px 16px;
    width: 90vw;
  }
  .reviewContainer {
    color: #ffffff;
    position: relative;
    overflow-y: scroll;
    min-height: 120px;

    box-shadow: inset 0px 0px 10px rgba(0, 103, 62, 0.061);
    border-radius: 8px;
    -webkit-transition: 0.15s ease-in 0s;
    -moz-transition: 0.15s ease-in 0s;
    -o-transition: 0.15s ease-in 0s;
    transition: 0.15s ease-in 0s;
  }

  .review {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1000;
    overflow-y: scroll;

    z-index: 50;
    height: max-content;
  }
  .reviewContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    overflow-y: hidden;
  }

  .reviewContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #008852;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .reviewContainer::-webkit-scrollbar-track {
    -webkit-appearance: none;
    background-color: #00673e00;
  }
}

.attributionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  gap: 16px;
  margin-top: auto;
}
.nameAndDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  color: #ffffff;
}

////////////////////////////////////////////
@media only screen and (max-width: 344px) {
  .testimonialItem {
    margin-inline-start: 3.5vw;
    max-width: 67vw;
  }
}

@media only screen and (max-width: 360px) and (min-width: 345px) {
  .testimonialItem {
    margin-inline-start: 3.5vw;
    max-width: 67vw;
  }
}
@media only screen and (max-width: 425px) and (min-width: 361px) {
  .testimonialItem {
    margin-inline-start: 3.5vw;
    display: flex;
    flex-direction: column;
    max-width: 67vw;
  }
}
@media only screen and (max-width: 600px) and (min-width: 426px) {
  .testimonialItem {
    margin-inline-start: 3.5vw;
    max-width: 67vw;
  }
}
