/*this file for refrence only not imported
change here then conver scss to css then minify the css to single line.

https://jsonformatter.org/scss-to-css
https://www.toptal.com/developers/cssminifier
*/

.sold_testimonial_section {
  background: #00341f;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 96px 0;

  .slick-track {
    p {
      margin-bottom: 0;
    }
  }

  .title {
    color: #fff;
    display: block;
    letter-spacing: -0.04em;
    line-height: 100%;
    margin-bottom: 16px;
    text-align: center;

    span {
      color: #3b9c53;
    }
  }

  .subTitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 200%;
    /* identical to box height, or 30px */
    text-align: center;
    letter-spacing: -0.03em;
    /* White */
    color: #fff;
    opacity: 0.7;
    margin-bottom: 25px;
  }

  .arrowContainer {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    padding: 12px 80px;
  }
}

@media only screen and (max-width: 600px) {
  .sold_testimonial_section .arrowContainer {
    padding: 12px 80px;
    justify-content: center;
  }
}

.testimonials {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.testimonials > div > div {
  display: grid;
  height: 300px;
}

@media only screen and (max-width: 600px) {
  .testimonials > div > div {
    height: 300px;
  }
}

.testimonials > div > div > div > div > div {
  display: grid;
  height: 150px;
}

@media only screen and (max-width: 600px) {
  .testimonials > div > div > div > div > div {
    height: 300px;
  }
}

.sliders > div {
  padding-left: 80px;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .sliders > div {
    padding-inline-start: 40px;
  }
}

.slidersAr > div {
  right: 80px;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .slidersAr > div {
    padding-inline-start: 40px;
    right: 0px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 426px) {
  .sold_testimonial_section {
    padding: 60px 0 60px;
  }
  .sold_testimonial_section h2 {
    font-size: 24px;
  }
  .sold_testimonial_section .subTitle {
    font-size: 13px;
  }

  .testimonials {
    padding: 0;
  }
}

@media only screen and (max-width: 425px) and (min-width: 361px) {
  .sold_testimonial_section {
    padding: 60px 0 60px;
  }
  .sold_testimonial_section h2 {
    font-size: 24px;
  }
  .sold_testimonial_section .subTitle {
    font-size: 13px;
  }
  .testimonials {
    padding: 0;
  }
}

@media only screen and (max-width: 360px) and (min-width: 345px) {
  .sold_testimonial_section {
    padding: 70px 0 100px;
  }
  .sold_testimonial_section h2 {
    font-size: 24px;
  }
  .sold_testimonial_section .subTitle {
    font-size: 13px;
  }
}

@media only screen and (max-width: 344px) {
  .sold_testimonial_section {
    padding: 50px 0 70px;
  }
  .sold_testimonial_section h2 {
    font-size: 24px;
  }
  .sold_testimonial_section .subTitle {
    font-size: 13px;
  }
  .testimonials {
    padding: 0;
  }
}
