@import "src/theme/colors.module.scss";

.sold {
  padding: 70px 0 75px;
  background: #050e1d;
  overflow-x: hidden;
  overflow-y: hidden;

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    /* identical to box height, or 36px */

    text-align: center;
    letter-spacing: -0.04em;

    /* White */

    color: #ffffff;
    margin-bottom: 16px;

    span {
      color: #3b9c53;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 200%;
    /* identical to box height, or 30px */

    text-align: center;
    letter-spacing: -0.03em;

    color: $dark-gray;
    opacity: 0.7;
    margin-bottom: 40px;
  }
}

.whiteBg {
  text-align: center;
  background: #f6f6f6;
  position: relative;

  h2 {
    color: #1a273b;
  }

  p {
    color: #0c0c0c;
    // margin-bottom: 0;
  }

  img {
    margin: 0 auto;
  }
}
.animationContainer {
  position: absolute;
  width: 100%;
  margin-bottom: 10%;
  transform: translate(0, -110%);

  .reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 0.7s all ease-in-out;
  }

  .reveal_active {
    transform: translateY(0);
    opacity: 1;
  }

  .anim_title {
    margin: 2% 0px 0px 0px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    text-align: center;
    opacity: 1;
  }
}
.margin100 {
  margin-top: 100px;
}

.whySellContainer {
  display: flex;
  max-width: 70%;
  gap: 2rem;
  justify-content: space-between;
  margin: 0px auto;
  height: 300px;
}

@media only screen and (max-width: 1000px) {
  .whySellContainer {
    max-width: 80%;
  }
}

@media only screen and (max-width: 850px) {
  .whySellContainer {
    max-width: 90%;
  }
}

.middle_animCard {
  display: inline-block !important;
  margin-top: 50px;
  max-width: 290px;
}

.animCard {
  background: #ffffff;
  box-shadow: 0px 17px 68px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  max-width: 290px;
  height: fit-content;

  // align-items: flex-start;
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 18px */

    letter-spacing: -0.01em;

    /* Secondary / Default */

    color: #1a273b;
  }

  .animText {
    color: #0c0c0c;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.03em;
    line-height: 160%;
    margin-bottom: 0;
    opacity: 0.7;
  }

  .animTextDesktop {
    color: #0c0c0c;
    font-size: 0.6875rem;
    font-weight: 400;
    letter-spacing: -0.03em;
    line-height: 160%;
    margin-bottom: 0;
    opacity: 0.7;
  }
}

.mobileContainer {
  display: none;
}

@media only screen and (max-width: 770px) {
  .reveal {
    position: relative;

    display: none;
  }

  .mobileContainer {
    display: block;
  }

  .animCard {
    height: 220px;
  }

  .reveal_active {
    display: none;
  }
  .middle_animCard {
    display: none !important;
    margin-top: 0;
  }
}
@media only screen and (max-width: 600px) and (min-width: 426px) {
  .sold {
    // padding: 80px 0 100px;
    padding: 60px 0 60px;

    h2 {
      font-size: 24px;
    }

    .subtitle {
      font-size: 13px;
    }
  }
  .whiteBg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .slideAnim {
    position: absolute;
    bottom: 0;
    transform: none;
    width: 100%;

    .animCard {
      img {
        width: 25%;
      }
    }
  }
  .reveal {
    position: relative;

    display: none;
  }

  .reveal_active {
    display: none;
  }
  .middle_animCard {
    display: none !important;
    margin-top: 0;
  }
}
@media only screen and (max-width: 425px) and (min-width: 361px) {
  .sold {
    // padding: 80px 0 100px;
    padding: 60px 0 60px;

    h2 {
      font-size: 24px;
    }

    .subtitle {
      font-size: 13px;
    }
  }
  .whiteBg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .slideAnim {
    position: absolute;
    bottom: 0;
    transform: none;
    width: 100%;

    .animCard {
      img {
        width: 25%;
      }
    }
  }
  .reveal {
    position: relative;

    display: none;
  }

  .reveal_active {
    display: none;
  }
  .middle_animCard {
    display: none !important;
    margin-top: 0;
  }
}
@media only screen and (max-width: 360px) and (min-width: 345px) {
  .sold {
    padding: 70px 0 100px;

    h2 {
      font-size: 24px;
    }

    .subtitle {
      font-size: 13px;
    }
  }
  .whiteBg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .slideAnim {
    position: absolute;
    bottom: 0;
    transform: none;
    width: 100%;

    .animCard {
      img {
        width: 25%;
      }
    }
  }
  .reveal {
    position: relative;

    display: none;
  }

  .reveal_active {
    display: none;
  }
  .middle_animCard {
    display: none !important;
    margin-top: 0;
  }
}

@media only screen and (max-width: 344px) {
  .sold {
    padding: 50px 0 70px;

    h2 {
      font-size: 24px;
    }

    .subtitle {
      font-size: 13px;
    }
  }
  .whiteBg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .slideAnim {
    position: absolute;
    bottom: 0;
    transform: none;
    width: 100%;

    .animCard {
      img {
        width: 25%;
      }
    }
  }
  .reveal {
    position: relative;

    display: none;
  }

  .reveal_active {
    display: none;
  }
  .middle_animCard {
    display: none !important;
    margin-top: 0;
  }
}
