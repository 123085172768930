$white: #ffffff;
$black: #000000;
$dark-gray: #0c0c0c;
$gray: #667085;
$primary: #009b5d;
$secondary: #002d5d;
$error: #f04438;
$warning: #f79009;
$success: #12b76a;
$gray-blue: #4e5ba6;
$gray-cool: #5d6b98;
$gray-modern: #697586;
$gray-neutral: #6c737f;
$gray-iron: #70707b;
$gray-true: #737373;
$gray-warm: #79716b;
$moss: #669f2a;
$green-light: #66c61c;
$green: #16b364;
$teal: #15b79e;
$cyan: #06aed4;
$blue-light: #0ba5ec;
$blue: #2e90fa;
$blue-dark: #2970ff;
$indigo: #6172f3;
$violet: #875bf7;
$purple: #7a5af8;
$fuchsia: #d444f1;
$pink: #ee46bc;
$rose: #f63d68;
$orange-dark: #ff4405;
$orange: #ef6820;
$yellow: #eaaa08;

$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;
$gray-900-opac: #1018280d;
$gray-900-14: #10182814;
$gray-900-08: #10182808;

$primary-25: #f3fffa;
$primary-50: #e6fbf2;
$primary-100: #ccf5e5;
$primary-200: #99ebcb;
$primary-300: #66e2b0;
$primary-400: #33d896;
$primary-500: #00ce7c;
$primary-600: #009b5d;
$primary-700: #00673e;
$primary-800: #00341f;
$primary-900: #00150c;

$secondary-25: #e9eaff;
$secondary-50: #e6ebef;
$secondary-100: #ccd5df;
$secondary-200: #99abbe;
$secondary-300: #66819e;
$secondary-400: #33577d;
$secondary-500: #002d5d;
$secondary-600: #002246;
$secondary-700: #00172f;
$secondary-800: #000b17;
$secondary-900: #000509;

$error-25: #fffbfa;
$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-400: #f97066;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$error-800: #912018;
$error-900: #7a271a;

$warning-25: #fffcf5;
$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #7a2e0e;

$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-200: #a6f4c5;
$success-300: #6ce9a6;
$success-400: #32d583;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;
$success-800: #05603a;
$success-900: #054f31;

$gray-blue-25: #fcfcfd;
$gray-blue-50: #f8f9fc;
$gray-blue-100: #eaecf5;
$gray-blue-200: #d5d9eb;
$gray-blue-300: #b3b8db;
$gray-blue-400: #717bbc;
$gray-blue-500: #4e5ba6;
$gray-blue-600: #3e4784;
$gray-blue-700: #363f72;
$gray-blue-800: #293056;
$gray-blue-900: #101323;

$gray-cool-25: #fcfcfd;
$gray-cool-50: #f9f9fb;
$gray-cool-100: #eff1f5;
$gray-cool-200: #dcdfea;
$gray-cool-300: #b9c0d4;
$gray-cool-400: #7d89b0;
$gray-cool-500: #5d6b98;
$gray-cool-600: #4a5578;
$gray-cool-700: #404968;
$gray-cool-800: #30374f;
$gray-cool-900: #111322;

$gray-modern-25: #fcfcfd;
$gray-modern-50: #f8fafc;
$gray-modern-100: #eef2f6;
$gray-modern-200: #e3e8ef;
$gray-modern-300: #cdd5df;
$gray-modern-400: #9aa4b2;
$gray-modern-500: #697586;
$gray-modern-600: #4b5565;
$gray-modern-700: #364152;
$gray-modern-800: #202939;
$gray-modern-900: #121926;

$gray-neutral-25: #fcfcfd;
$gray-neutral-50: #f9fafb;
$gray-neutral-100: #f3f4f6;
$gray-neutral-200: #e5e7eb;
$gray-neutral-300: #d2d6db;
$gray-neutral-400: #9da4ae;
$gray-neutral-500: #6c737f;
$gray-neutral-600: #4d5761;
$gray-neutral-700: #384250;
$gray-neutral-800: #1f2a37;
$gray-neutral-900: #111927;

$gray-iron-25: #fcfcfc;
$gray-iron-50: #fafafa;
$gray-iron-100: #f4f4f5;
$gray-iron-200: #e4e4e7;
$gray-iron-300: #d1d1d6;
$gray-iron-400: #a0a0ab;
$gray-iron-500: #70707b;
$gray-iron-600: #51525c;
$gray-iron-700: #3f3f46;
$gray-iron-800: #26272b;
$gray-iron-900: #18181b;

$gray-true-25: #fcfcfc;
$gray-true-50: #fafafa;
$gray-true-100: #f5f5f5;
$gray-true-200: #e5e5e5;
$gray-true-300: #d6d6d6;
$gray-true-400: #a3a3a3;
$gray-true-500: #737373;
$gray-true-600: #525252;
$gray-true-700: #424242;
$gray-true-800: #292929;
$gray-true-900: #141414;

$gray-warm-25: #fdfdfc;
$gray-warm-50: #fafaf9;
$gray-warm-100: #f5f5f4;
$gray-warm-200: #e7e5e4;
$gray-warm-300: #d7d3d0;
$gray-warm-400: #a9a29d;
$gray-warm-500: #79716b;
$gray-warm-600: #57534e;
$gray-warm-700: #44403c;
$gray-warm-800: #292524;
$gray-warm-900: #1c1917;

$moss-25: #fafdf7;
$moss-50: #f5fbee;
$moss-100: #e6f4d7;
$moss-200: #ceeab0;
$moss-300: #acdc79;
$moss-400: #86cb3c;
$moss-500: #669f2a;
$moss-600: #4f7a21;
$moss-700: #3f621a;
$moss-800: #335015;
$moss-900: #2b4212;

$green-light-25: #fafef5;
$green-light-50: #f3fee7;
$green-light-100: #e3fbcc;
$green-light-200: #d0f8ab;
$green-light-300: #a6ef67;
$green-light-400: #85e13a;
$green-light-500: #66c61c;
$green-light-600: #4ca30d;
$green-light-700: #3b7c0f;
$green-light-800: #326212;
$green-light-900: #2b5314;

$green-25: #f6fef9;
$green-50: #edfcf2;
$green-100: #d3f8df;
$green-200: #aaf0c4;
$green-300: #73e2a3;
$green-400: #3ccb7f;
$green-500: #16b364;
$green-600: #099250;
$green-700: #087443;
$green-800: #095c37;
$green-900: #084c2e;

$teal-25: #f6fefc;
$teal-50: #f0fdf9;
$teal-100: #ccfbef;
$teal-200: #99f6e0;
$teal-300: #5fe9d0;
$teal-400: #2ed3b7;
$teal-500: #15b79e;
$teal-600: #0e9384;
$teal-700: #107569;
$teal-800: #125d56;
$teal-900: #134e48;

$cyan-25: #f5feff;
$cyan-50: #ecfdff;
$cyan-100: #cff9fe;
$cyan-200: #a5f0fc;
$cyan-300: #67e3f9;
$cyan-400: #22ccee;
$cyan-500: #06aed4;
$cyan-600: #088ab2;
$cyan-700: #0e7090;
$cyan-800: #155b75;
$cyan-900: #164c63;

$blue-light-25: #f5fbff;
$blue-light-50: #f0f9ff;
$blue-light-100: #e0f2fe;
$blue-light-200: #b9e6fe;
$blue-light-300: #7cd4fd;
$blue-light-400: #36bffa;
$blue-light-500: #0ba5ec;
$blue-light-600: #0086c9;
$blue-light-700: #026aa2;
$blue-light-800: #065986;
$blue-light-900: #0b4a6f;

$blue-25: #f5faff;
$blue-50: #eff8ff;
$blue-100: #d1e9ff;
$blue-200: #b2ddff;
$blue-300: #84caff;
$blue-400: #53b1fd;
$blue-500: #2e90fa;
$blue-600: #1570ef;
$blue-700: #175cd3;
$blue-800: #1849a9;
$blue-900: #194185;

$blue-dark-25: #f5f8ff;
$blue-dark-50: #eff4ff;
$blue-dark-100: #d1e0ff;
$blue-dark-200: #b2ccff;
$blue-dark-300: #84adff;
$blue-dark-400: #528bff;
$blue-dark-500: #2970ff;
$blue-dark-600: #155eef;
$blue-dark-700: #004eeb;
$blue-dark-800: #0040c1;
$blue-dark-900: #00359e;

$indigo-25: #f5f8ff;
$indigo-50: #eef4ff;
$indigo-100: #e0eaff;
$indigo-200: #c7d7fe;
$indigo-300: #a4bcfd;
$indigo-400: #8098f9;
$indigo-500: #6172f3;
$indigo-600: #444ce7;
$indigo-700: #3538cd;
$indigo-800: #2d31a6;
$indigo-900: #2d3282;

$violet-25: #fbfaff;
$violet-50: #f5f3ff;
$violet-100: #ece9fe;
$violet-200: #ddd6fe;
$violet-300: #c3b5fd;
$violet-400: #a48afb;
$violet-500: #875bf7;
$violet-600: #7839ee;
$violet-700: #6927da;
$violet-800: #5720b7;
$violet-900: #491c96;

$purple-25: #fafaff;
$purple-50: #f4f3ff;
$purple-100: #ebe9fe;
$purple-200: #d9d6fe;
$purple-300: #bdb4fe;
$purple-400: #9b8afb;
$purple-500: #7a5af8;
$purple-600: #6938ef;
$purple-700: #5925dc;
$purple-800: #4a1fb8;
$purple-900: #3e1c96;

$fuchsia-25: #fefaff;
$fuchsia-50: #fdf4ff;
$fuchsia-100: #fbe8ff;
$fuchsia-200: #f6d0fe;
$fuchsia-300: #eeaafd;
$fuchsia-400: #e478fa;
$fuchsia-500: #d444f1;
$fuchsia-600: #ba24d5;
$fuchsia-700: #9f1ab1;
$fuchsia-800: #821890;
$fuchsia-900: #6f1877;

$pink-25: #fef6fb;
$pink-50: #fdf2fa;
$pink-100: #fce7f6;
$pink-200: #fcceee;
$pink-300: #faa7e0;
$pink-400: #f670c7;
$pink-500: #ee46bc;
$pink-600: #dd2590;
$pink-700: #c11574;
$pink-800: #9e165f;
$pink-900: #851651;

$rose-25: #fff5f6;
$rose-50: #fff1f3;
$rose-100: #ffe4e8;
$rose-200: #fecdd6;
$rose-300: #fea3b4;
$rose-400: #fd6f8e;
$rose-500: #f63d68;
$rose-600: #e31b54;
$rose-700: #c01048;
$rose-800: #a11043;
$rose-900: #89123e;

$orange-dark-25: #fff9f5;
$orange-dark-50: #fff4ed;
$orange-dark-100: #ffe6d5;
$orange-dark-200: #ffd6ae;
$orange-dark-300: #ff9c66;
$orange-dark-400: #ff692e;
$orange-dark-500: #ff4405;
$orange-dark-600: #e62e05;
$orange-dark-700: #bc1b06;
$orange-dark-800: #97180c;
$orange-dark-900: #771a0d;

$orange-25: #fefaf5;
$orange-50: #fef6ee;
$orange-100: #fdead7;
$orange-200: #f9dbaf;
$orange-300: #f7b27a;
$orange-400: #f38744;
$orange-500: #ef6820;
$orange-600: #e04f16;
$orange-700: #b93815;
$orange-800: #932f19;
$orange-900: #772917;

$yellow-25: #fefdf0;
$yellow-50: #fefbe8;
$yellow-100: #fef7c3;
$yellow-200: #feee95;
$yellow-300: #fde272;
$yellow-400: #fac515;
$yellow-500: #eaaa08;
$yellow-600: #ca8504;
$yellow-700: #a15c07;
$yellow-800: #854a0e;
$yellow-900: #713b12;

$colors: (
        "white": $white,
        "black": $black,
        "gray": $gray,
        "darkGray": $dark-gray,
        "gray25": $gray-25,
        "gray50": $gray-50,
        "gray100": $gray-100,
        "gray200": $gray-200,
        "gray300": $gray-300,
        "gray400": $gray-400,
        "gray500": $gray-500,
        "gray600": $gray-600,
        "gray700": $gray-700,
        "gray800": $gray-800,
        "gray900": $gray-900,
        "primary": $primary,
        "primary25": $primary-25,
        "primary50": $primary-50,
        "primary100": $primary-100,
        "primary200": $primary-200,
        "primary300": $primary-300,
        "primary400": $primary-400,
        "primary500": $primary-500,
        "primary600": $primary-600,
        "primary700": $primary-700,
        "primary800": $primary-800,
        "primary900": $primary-900,
        "secondary": $secondary,
        "secondary25": $secondary-25,
        "secondary50": $secondary-50,
        "secondary100": $secondary-100,
        "secondary200": $secondary-200,
        "secondary300": $secondary-300,
        "secondary400": $secondary-400,
        "secondary500": $secondary-500,
        "secondary600": $secondary-600,
        "secondary700": $secondary-700,
        "secondary800": $secondary-800,
        "secondary900": $secondary-900,
        "error": $error,
        "error25": $error-25,
        "error50": $error-50,
        "error100": $error-100,
        "error200": $error-200,
        "error300": $error-300,
        "error400": $error-400,
        "error500": $error-500,
        "error600": $error-600,
        "error700": $error-700,
        "error800": $error-800,
        "error900": $error-900,
        "warning": $warning,
        "warning25": $warning-25,
        "warning50": $warning-50,
        "warning100": $warning-100,
        "warning200": $warning-200,
        "warning300": $warning-300,
        "warning400": $warning-400,
        "warning500": $warning-500,
        "warning600": $warning-600,
        "warning700": $warning-700,
        "warning800": $warning-800,
        "warning900": $warning-900,
        "success": $success,
        "success25": $success-25,
        "success50": $success-50,
        "success100": $success-100,
        "success200": $success-200,
        "success300": $success-300,
        "success400": $success-400,
        "success500": $success-500,
        "success600": $success-600,
        "success700": $success-700,
        "success800": $success-800,
        "success900": $success-900,
        "grayBlue": $gray-blue,
        "grayBlue25": $gray-blue-25,
        "grayBlue50": $gray-blue-50,
        "grayBlue100": $gray-blue-100,
        "grayBlue200": $gray-blue-200,
        "grayBlue300": $gray-blue-300,
        "grayBlue400": $gray-blue-400,
        "grayBlue500": $gray-blue-500,
        "grayBlue600": $gray-blue-600,
        "grayBlue700": $gray-blue-700,
        "grayBlue800": $gray-blue-800,
        "grayBlue900": $gray-blue-900,
        "grayCool": $gray-cool,
        "grayCool25": $gray-cool-25,
        "grayCool50": $gray-cool-50,
        "grayCool100": $gray-cool-100,
        "grayCool200": $gray-cool-200,
        "grayCool300": $gray-cool-300,
        "grayCool400": $gray-cool-400,
        "grayCool500": $gray-cool-500,
        "grayCool600": $gray-cool-600,
        "grayCool700": $gray-cool-700,
        "grayCool800": $gray-cool-800,
        "grayCool900": $gray-cool-900,
        "grayModern": $gray-modern,
        "grayModern25": $gray-modern-25,
        "grayModern50": $gray-modern-50,
        "grayModern100": $gray-modern-100,
        "grayModern200": $gray-modern-200,
        "grayModern300": $gray-modern-300,
        "grayModern400": $gray-modern-400,
        "grayModern500": $gray-modern-500,
        "grayModern600": $gray-modern-600,
        "grayModern700": $gray-modern-700,
        "grayModern800": $gray-modern-800,
        "grayModern900": $gray-modern-900,
        "grayNeutral": $gray-neutral,
        "grayNeutral25": $gray-neutral-25,
        "grayNeutral50": $gray-neutral-50,
        "grayNeutral100": $gray-neutral-100,
        "grayNeutral200": $gray-neutral-200,
        "grayNeutral300": $gray-neutral-300,
        "grayNeutral400": $gray-neutral-400,
        "grayNeutral500": $gray-neutral-500,
        "grayNeutral600": $gray-neutral-600,
        "grayNeutral700": $gray-neutral-700,
        "grayNeutral800": $gray-neutral-800,
        "grayNeutral900": $gray-neutral-900,
        "grayIron": $gray-iron,
        "grayIron25": $gray-iron-25,
        "grayIron50": $gray-iron-50,
        "grayIron100": $gray-iron-100,
        "grayIron200": $gray-iron-200,
        "grayIron300": $gray-iron-300,
        "grayIron400": $gray-iron-400,
        "grayIron500": $gray-iron-500,
        "grayIron600": $gray-iron-600,
        "grayIron700": $gray-iron-700,
        "grayIron800": $gray-iron-800,
        "grayIron900": $gray-iron-900,
        "grayTrue": $gray-true,
        "grayTrue25": $gray-true-25,
        "grayTrue50": $gray-true-50,
        "grayTrue100": $gray-true-100,
        "grayTrue200": $gray-true-200,
        "grayTrue300": $gray-true-300,
        "grayTrue400": $gray-true-400,
        "grayTrue500": $gray-true-500,
        "grayTrue600": $gray-true-600,
        "grayTrue700": $gray-true-700,
        "grayTrue800": $gray-true-800,
        "grayTrue900": $gray-true-900,
        "grayWarm": $gray-warm,
        "grayWarm25": $gray-warm-25,
        "grayWarm50": $gray-warm-50,
        "grayWarm100": $gray-warm-100,
        "grayWarm200": $gray-warm-200,
        "grayWarm300": $gray-warm-300,
        "grayWarm400": $gray-warm-400,
        "grayWarm500": $gray-warm-500,
        "grayWarm600": $gray-warm-600,
        "grayWarm700": $gray-warm-700,
        "grayWarm800": $gray-warm-800,
        "grayWarm900": $gray-warm-900,
        "moss": $moss,
        "moss25": $moss-25,
        "moss50": $moss-50,
        "moss100": $moss-100,
        "moss200": $moss-200,
        "moss300": $moss-300,
        "moss400": $moss-400,
        "moss500": $moss-500,
        "moss600": $moss-600,
        "moss700": $moss-700,
        "moss800": $moss-800,
        "moss900": $moss-900,
        "greenLight": $green-light,
        "greenLight25": $green-light-25,
        "greenLight50": $green-light-50,
        "greenLight100": $green-light-100,
        "greenLight200": $green-light-200,
        "greenLight300": $green-light-300,
        "greenLight400": $green-light-400,
        "greenLight500": $green-light-500,
        "greenLight600": $green-light-600,
        "greenLight700": $green-light-700,
        "greenLight800": $green-light-800,
        "greenLight900": $green-light-900,
        "green": $green,
        "green25": $green-25,
        "green50": $green-50,
        "green100": $green-100,
        "green200": $green-200,
        "green300": $green-300,
        "green400": $green-400,
        "green500": $green-500,
        "green600": $green-600,
        "green700": $green-700,
        "green800": $green-800,
        "green900": $green-900,
        "teal": $teal,
        "teal25": $teal-25,
        "teal50": $teal-50,
        "teal100": $teal-100,
        "teal200": $teal-200,
        "teal300": $teal-300,
        "teal400": $teal-400,
        "teal500": $teal-500,
        "teal600": $teal-600,
        "teal700": $teal-700,
        "teal800": $teal-800,
        "teal900": $teal-900,
        "cyan": $cyan,
        "cyan25": $cyan-25,
        "cyan50": $cyan-50,
        "cyan100": $cyan-100,
        "cyan200": $cyan-200,
        "cyan300": $cyan-300,
        "cyan400": $cyan-400,
        "cyan500": $cyan-500,
        "cyan600": $cyan-600,
        "cyan700": $cyan-700,
        "cyan800": $cyan-800,
        "cyan900": $cyan-900,
        "blueLight": $blue-light,
        "blueLight25": $blue-light-25,
        "blueLight50": $blue-light-50,
        "blueLight100": $blue-light-100,
        "blueLight200": $blue-light-200,
        "blueLight300": $blue-light-300,
        "blueLight400": $blue-light-400,
        "blueLight500": $blue-light-500,
        "blueLight600": $blue-light-600,
        "blueLight700": $blue-light-700,
        "blueLight800": $blue-light-800,
        "blueLight900": $blue-light-900,
        "blue": $blue,
        "blue25": $blue-25,
        "blue50": $blue-50,
        "blue100": $blue-100,
        "blue200": $blue-200,
        "blue300": $blue-300,
        "blue400": $blue-400,
        "blue500": $blue-500,
        "blue600": $blue-600,
        "blue700": $blue-700,
        "blue800": $blue-800,
        "blue900": $blue-900,
        "blueDark": $blue-dark,
        "blueDark25": $blue-dark-25,
        "blueDark50": $blue-dark-50,
        "blueDark100": $blue-dark-100,
        "blueDark200": $blue-dark-200,
        "blueDark300": $blue-dark-300,
        "blueDark400": $blue-dark-400,
        "blueDark500": $blue-dark-500,
        "blueDark600": $blue-dark-600,
        "blueDark700": $blue-dark-700,
        "blueDark800": $blue-dark-800,
        "blueDark900": $blue-dark-900,
        "indigo": $indigo,
        "indigo25": $indigo-25,
        "indigo50": $indigo-50,
        "indigo100": $indigo-100,
        "indigo200": $indigo-200,
        "indigo300": $indigo-300,
        "indigo400": $indigo-400,
        "indigo500": $indigo-500,
        "indigo600": $indigo-600,
        "indigo700": $indigo-700,
        "indigo800": $indigo-800,
        "indigo900": $indigo-900,
        "violet": $violet,
        "violet25": $violet-25,
        "violet50": $violet-50,
        "violet100": $violet-100,
        "violet200": $violet-200,
        "violet300": $violet-300,
        "violet400": $violet-400,
        "violet500": $violet-500,
        "violet600": $violet-600,
        "violet700": $violet-700,
        "violet800": $violet-800,
        "violet900": $violet-900,
        "purple": $purple,
        "purple25": $purple-25,
        "purple50": $purple-50,
        "purple100": $purple-100,
        "purple200": $purple-200,
        "purple300": $purple-300,
        "purple400": $purple-400,
        "purple500": $purple-500,
        "purple600": $purple-600,
        "purple700": $purple-700,
        "purple800": $purple-800,
        "purple900": $purple-900,
        "fuchsia": $fuchsia,
        "fuchsia25": $fuchsia-25,
        "fuchsia50": $fuchsia-50,
        "fuchsia100": $fuchsia-100,
        "fuchsia200": $fuchsia-200,
        "fuchsia300": $fuchsia-300,
        "fuchsia400": $fuchsia-400,
        "fuchsia500": $fuchsia-500,
        "fuchsia600": $fuchsia-600,
        "fuchsia700": $fuchsia-700,
        "fuchsia800": $fuchsia-800,
        "fuchsia900": $fuchsia-900,
        "pink": $pink,
        "pink25": $pink-25,
        "pink50": $pink-50,
        "pink100": $pink-100,
        "pink200": $pink-200,
        "pink300": $pink-300,
        "pink400": $pink-400,
        "pink500": $pink-500,
        "pink600": $pink-600,
        "pink700": $pink-700,
        "pink800": $pink-800,
        "pink900": $pink-900,
        "rose": $rose,
        "rose25": $rose-25,
        "rose50": $rose-50,
        "rose100": $rose-100,
        "rose200": $rose-200,
        "rose300": $rose-300,
        "rose400": $rose-400,
        "rose500": $rose-500,
        "rose600": $rose-600,
        "rose700": $rose-700,
        "rose800": $rose-800,
        "rose900": $rose-900,
        "orangeDark": $orange-dark,
        "orangeDark25": $orange-dark-25,
        "orangeDark50": $orange-dark-50,
        "orangeDark100": $orange-dark-100,
        "orangeDark200": $orange-dark-200,
        "orangeDark300": $orange-dark-300,
        "orangeDark400": $orange-dark-400,
        "orangeDark500": $orange-dark-500,
        "orangeDark600": $orange-dark-600,
        "orangeDark700": $orange-dark-700,
        "orangeDark800": $orange-dark-800,
        "orangeDark900": $orange-dark-900,
        "orange": $orange,
        "orange25": $orange-25,
        "orange50": $orange-50,
        "orange100": $orange-100,
        "orange200": $orange-200,
        "orange300": $orange-300,
        "orange400": $orange-400,
        "orange500": $orange-500,
        "orange600": $orange-600,
        "orange700": $orange-700,
        "orange800": $orange-800,
        "orange900": $orange-900,
        "yellow": $yellow,
        "yellow25": $yellow-25,
        "yellow50": $yellow-50,
        "yellow100": $yellow-100,
        "yellow200": $yellow-200,
        "yellow300": $yellow-300,
        "yellow400": $yellow-400,
        "yellow500": $yellow-500,
        "yellow600": $yellow-600,
        "yellow700": $yellow-700,
        "yellow800": $yellow-800,
        "yellow900": $yellow-900,
);

:export {
  @each $name, $value in $colors {
    #{unquote($name)}: $value;
  }
}
